.text-section {
  ul {
    list-style: none;
    margin-top: 10px;
    padding-inline-start: 0 !important;

    li {
      font-size: 22px;
      text-transform: uppercase;
      margin-bottom: 7px;
      @media (max-width: 600px) {
        font-size: 17px;
      }
    }
  }
  a {
    color: black;
    font-weight: 500;
  }
}
.contactbox {
  ul {
    padding-inline-start: 0 !important;
    .piksel-wysiwyg-litem {
      a {
        color: #1769b2;
      }
    }
  }
}
