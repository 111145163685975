.banner {
  width: 100%;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .title-banner {
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 44px;
    font-weight: normal;
    @media screen and (max-width: 600px) {
      font-size: 25px;
    }
  }
  &.video {
    background: #202857;
    overflow: hidden;
    display: block;
    padding: 0;
    position: relative;
    @media screen and (max-width: 499px) {
      height: 0;
      padding-top: 128%;
      width: 100%;
    }
    .btn-vol {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 1px solid #fff;
      position: absolute;
      bottom: 2rem;
      right: 2rem;
      z-index: 1000;
      font-size: 0;
      background-size: 60%;
      background-position: center center ;
      background-repeat: no-repeat;
      background-image: url(../img/audio-off.svg);
      opacity: .5;
      cursor: pointer;
      transition: opacity .15s ease-in-out;
      &:hover {
        opacity: 1;
      }
      &.unmute{
        background-image: url(../img/audio-on.svg);
      }
    }
    .text-content {
      z-index: 3;
    }
    .video-background {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: 100%;
      z-index: 1;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      opacity: 1;
      @media (min-aspect-ratio: 16/9) {
        width: 100%;
        height: auto;
      }
      &.mobile-on {
        width: 100%;
      }
    }
    .visore-hp {
      position: relative;
      width: 100%;
      height: 100vh;
      background: center center no-repeat;
      background-size: cover;
      background-color: #202857;
      overflow: hidden;
      z-index: 0;
    }
    .btn-down {
      background-color: white;
      position: absolute;
      z-index: 100;
      left: 50%;
      transform: translateX(-50%);
      top: 92vh;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: bounce2 2s ease infinite;
      box-shadow: 0.5px 0.5px 0.5px #333;
      color: #202857;
      font-size: 25px;
      text-decoration: none;
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
    @keyframes bounce2 {
      0%,
      20%,
      50%,
      80%,
      100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-30px);
      }
      60% {
        transform: translateY(-15px);
      }
    }
  }
}
.slider-section {
  .carousel-item {
    .image {
      // background-repeat: no-repeat;
      // background-size: cover;
      //height: 45vh;
      background: #202857;
      height: 440px;
      overflow: hidden;
      object-fit: cover;
      @media screen and (max-width: 600px) {
        height: 250px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 1;
      }
    }
  }
  .carousel-indicators {
    top: 450px;
    bottom: unset;
    @media screen and (max-width: 600px) {
      top: 260px;
    }
    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: grey;
      &.active {
        background-color: #006db3;
      }
    }
  }
}
.text-content {
  // background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
h1 {
  color: white;
  font-family: 'Nobile', sans-serif;
  font-size: 9vw;
  text-align: center;
}
