.content-page {
  .name-logo {
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    .title-page {
      font-size: 32px;
      text-transform: uppercase;
      color: #006db3;
      margin-bottom: 0;
      @media screen and (max-width: 600px) {
        font-size: 24px;
      }
    }
    .back-button {
      border: 1px solid #b1b3b4;
      color: #b1b3b4;
      border-radius: 24px;
      padding: 5px 35px;
      text-transform: uppercase;
      font-weight: normal;
      @media screen and (max-width: 600px) {
        padding: 2px 25px;
        font-size: 12px;
      }
    }
  }
  img.logo {
    width: auto;
    height: auto;
    margin: 20px 0;
    @media screen and (max-width: 600px) {
      margin-bottom: 30px;
      img {
        height: 56px;
      }
    }
  }
  p.text {
    font-size: 19px;
    margin-bottom: 20px;
    @media screen and (max-width: 600px) {
      font-size: 18px;
      margin-bottom: 40px;
    }
  }
  .icon-box {
    @media screen and (max-width: 600px) {
      align-items: baseline;
    }
    .piksel-wysiwyg-litem {
      flex: 0 0 25%;
      align-items: baseline;
      @media screen and (max-width: 600px) {
        flex: 0 0 50%;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      figure {
        margin-bottom: 10px;
        img {
          width: 80px;
          height: 80px;
          padding: 15px;
        }
      }
      .subtitle {
        font-size: 34px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .sub-subtitle {
        font-size: 16px;
        padding-right: 10px;
        text-align: left;
        @media screen and (max-width: 600px) {
          text-align: center;
          padding-right: 0;
        }
      }
      .digitalia-img {
        width: 80px;
        height: auto;
        margin-bottom: 15px;
      }
    }
  }
  .dwnl-link {
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 16px;
    color: #000;
    @media screen and (max-width: 600px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
