.generic_page {
  .title-page {
    font-size: 40px;
    text-transform: uppercase;
    color: #006db3;
    margin-bottom: 0;
  }
  &.article {
    img {
      max-width: 100%;
    }

    .title-article {
      font-size: 40px;
      margin-bottom: 40px;
      color: #006db3;
      text-transform: uppercase;
    }

    .paragraphs {
      display: flex;
      flex-flow: column;

      .paragraph {
        margin-bottom: 80px;
        display: inline-block;

        .img__center {
          text-align: center;

          img {
            margin: 0 auto 40px;
          }
        }

        .img__left {
          float: left;
          margin-right: 30px;
          width: 33%;
        }

        .img__right {
          float: right;
          margin-left: 30px;
          width: 33%;
        }

        .title-section {
          text-align: left;
          text-transform: initial;
          font-size: 30px;
        }

        .text-section {
          text-align: left;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
