.footer {
  background-color: #1769b2;
  padding: 50px 0;
  margin-top: 30px;
  @media screen and (max-width: 600px) {
    padding: 25px 0;
  }
  .logo-footer {
    margin-bottom: 20px;
    @media screen and (max-width: 600px) {
      margin: 0 auto;
      margin-bottom: 15px;
    }
    img {
      @media screen and (max-width: 600px) {
        max-width: 75%;
        margin: 0 auto;
        display: flex;
      }
    }
  }
  .content-footer {
    @media screen and (max-width: 600px) {
      padding: 0 20px;
    }
    .navbar-nav {
      border-right: 1px solid #fff;
      flex: 0 0 32%;
      @media screen and (max-width: 600px) {
        flex: 0 0 100%;
        border-right: 0;
        text-align: center;
        margin-bottom: 40px;
      }
      .nav-item {
        .nav-link {
          color: #fff;
          text-transform: uppercase;
          padding: 0.2rem 1rem;
        }
      }
    }
    .last-column {
      flex: 0 0 28%;
      padding: 0 30px;
      display: flex;
      justify-content: end;
      flex-direction: column;
      @media screen and (max-width: 600px) {
        margin-bottom: 0 !important;
      }
      @media screen and (max-width: 600px) {
        flex: 0 0 100%;
        border-right: 0;
        text-align: center;
        margin-bottom: 40px;
        justify-content: flex-start;
      }
      ul {
        border: 0;
        flex: none;
        @media screen and (max-width: 600px) {
          margin-bottom: 30px;
        }
        .nav-item {
          a {
            padding: 0 1rem !important;
            span {
              font-size: 12px;
            }
          }
        }
      }
      a {
        &:hover {
          text-decoration: none;
        }
      }
      .linkedin-flw {
        display: flex;
        align-items: flex-end;
        padding-left: 15px;
        @media screen and (max-width: 600px) {
          padding-left: 0;
          justify-content: center;
        }
        p {
          color: #fff;
          margin-bottom: 0;
          margin-right: 8px;
          font-weight: 500;
        }
        img {
          width: 20px;
          margin-bottom: 7px;
        }
      }
    }
    .contact {
      padding: 0 40px;
      border-right: 1px solid #fff;
      flex: 0 0 40%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      color: #fff;
      @media screen and (max-width: 600px) {
        flex: 0 0 100%;
        padding: 0;
        border-right: 0;
        text-align: center;
      }
      .society-name {
        font-size: 20px;
        font-weight: 400;
        @media screen and (max-width: 600px) {
          font-size: 18px;
        }
      }
      .txt-footer {
        font-size: 18px;
        margin-bottom: 0;
        line-height: 1.7;
        a {
          color: #fff;
          text-decoration: none;
          font-weight: 600;
          &:link {
            color: #fff;
          }
        }
      }
    }
  }
}
