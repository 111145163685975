@media (min-width: 1200px) {
  .container {
    max-width: 1024px !important;
  }
}

.header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), transparent);
}
.header.scrolled {
  background-image: none !important;
  background-color: #1769b2;
}
.header.full-height {
  height: 100vh;
  background-color: rgba(23, 105, 178, 0.95);
}
.header .navbar {
  padding: 25px !important;
  max-width: 100% !important;
}
@media (min-width: 1074px) {
  .header .navbar {
    padding: 25px 0 !important;
    max-width: 1024px !important;
  }
}
@media (min-width: 1221px) {
  .header .navbar {
    flex-flow: row;
  }
  .header .navbar .collapse {
    display: flex !important;
  }
}
@media (max-width: 1220px) {
  .header .navbar .logo-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.header .navbar .show .navbar-nav {
  display: block;
}
.header .navbar .navbar-nav {
  width: 100%;
  justify-content: space-evenly;
  flex-direction: column !important;
}
@media (max-width: 1220px) {
  .header .navbar .navbar-nav {
    margin-top: 20px;
  }
}
@media (min-width: 1221px) {
  .header .navbar .navbar-nav {
    flex-direction: row !important;
    display: flex !important;
  }
}
.header .navbar .navbar-nav .nav-item.active .nav-link {
  font-weight: bold;
}
.header .navbar .navbar-nav .nav-item a.nav-link {
  color: #fff;
  text-transform: uppercase;
  font-size: 17px;
}
@media (max-width: 1220px) {
  .header .navbar .navbar-brand {
    width: 190px;
  }
}
.header .navbar .navbar-brand img {
  max-width: 75%;
}
@media (min-width: 1221px) {
  .header .navbar .navbar-toggler {
    display: none !important;
  }
}
@media (max-width: 1220px) {
  .header .navbar .navbar-toggler {
    display: block !important;
  }
}

.generic_page {
  padding-top: 116px;
}
.generic_page .header {
  background-image: none !important;
  background-color: #1769b2;
}

.banner {
  width: 100%;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner .title-banner {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 44px;
  font-weight: normal;
}
@media screen and (max-width: 600px) {
  .banner .title-banner {
    font-size: 25px;
  }
}
.banner.video {
  background: #202857;
  overflow: hidden;
  display: block;
  padding: 0;
  position: relative;
}
@media screen and (max-width: 499px) {
  .banner.video {
    height: 0;
    padding-top: 128%;
    width: 100%;
  }
}
.banner.video .btn-vol {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #fff;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
  font-size: 0;
  background-size: 60%;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../img/audio-off.svg);
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.15s ease-in-out;
}
.banner.video .btn-vol:hover {
  opacity: 1;
}
.banner.video .btn-vol.unmute {
  background-image: url(../img/audio-on.svg);
}
.banner.video .text-content {
  z-index: 3;
}
.banner.video .video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: 100%;
  z-index: 1;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  opacity: 1;
}
@media (min-aspect-ratio: 16/9) {
  .banner.video .video-background {
    width: 100%;
    height: auto;
  }
}
.banner.video .video-background.mobile-on {
  width: 100%;
}
.banner.video .visore-hp {
  position: relative;
  width: 100%;
  height: 100vh;
  background: center center no-repeat;
  background-size: cover;
  background-color: #202857;
  overflow: hidden;
  z-index: 0;
}
.banner.video .btn-down {
  background-color: white;
  position: absolute;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  top: 92vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: bounce2 2s ease infinite;
  box-shadow: 0.5px 0.5px 0.5px #333;
  color: #202857;
  font-size: 25px;
  text-decoration: none;
}
@media screen and (max-width: 500px) {
  .banner.video .btn-down {
    display: none;
  }
}
@keyframes bounce2 {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.slider-section .carousel-item .image {
  background: #202857;
  height: 440px;
  overflow: hidden;
  object-fit: cover;
}
@media screen and (max-width: 600px) {
  .slider-section .carousel-item .image {
    height: 250px;
  }
}
.slider-section .carousel-item .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
}
.slider-section .carousel-indicators {
  top: 450px;
  bottom: unset;
}
@media screen and (max-width: 600px) {
  .slider-section .carousel-indicators {
    top: 260px;
  }
}
.slider-section .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: grey;
}
.slider-section .carousel-indicators li.active {
  background-color: #006db3;
}

.text-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  color: white;
  font-family: "Nobile", sans-serif;
  font-size: 9vw;
  text-align: center;
}

body {
  font-family: "Montserrat", sans-serif !important;
}
body.menu-opened {
  overflow: hidden;
}

section {
  padding: 30px 0 20px 0;
}
section.slider-section {
  padding: 0;
}
section.bg-grey {
  background-color: #f3f3f3;
}
section .title-section {
  color: #006db3;
  font-size: 34px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}
@media (max-width: 600px) {
  section .title-section {
    font-size: 23px;
  }
}
section .text-section {
  font-size: 19px;
  font-weight: 300;
  color: #000;
  text-align: center;
  margin-bottom: 30px;
  padding: 0 20px;
}
@media (max-width: 600px) {
  section .text-section {
    padding: 0;
    font-size: 18px;
  }
}
section .icon-box {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 600px) {
  section .icon-box {
    justify-content: space-around;
  }
}
section .icon-box > div {
  flex: 0 0 33.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  line-height: 1;
}
@media (max-width: 600px) {
  section .icon-box > div {
    flex: 0 0 40%;
  }
}
section .icon-box > div figure {
  background-image: url(/assets/img/cerchio.svg);
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
section .icon-box > div figure.no-circle {
  background-image: none;
}
section .icon-box > div figure.no-circle img {
  padding: 0 !important;
}
section .icon-box > div figure img {
  padding: 30px;
  width: 130px;
  height: 130px;
}
@media (max-width: 600px) {
  section .icon-box > div figure img {
    width: 90px;
    height: 90px;
    padding: 20px;
  }
}
section .icon-box > div .subtitle {
  font-size: 35px;
  color: #006db3;
  font-weight: bold;
  margin-bottom: 0;
}
@media (max-width: 600px) {
  section .icon-box > div .subtitle {
    font-size: 28px;
    margin-bottom: 6px;
  }
}
section .icon-box > div .sub-subtitle {
  font-size: 18px;
  color: #006db3;
  text-align: center;
  font-weight: 600;
}
@media (max-width: 600px) {
  section .icon-box > div .sub-subtitle {
    font-size: 18px;
    text-align: center;
  }
}
section .nav-tabs {
  justify-content: space-evenly;
  border: 0;
  margin-bottom: 40px;
}
@media (max-width: 600px) {
  section .nav-tabs {
    margin-bottom: 20px;
  }
}
section .nav-tabs.cont-button {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 600px) {
  section .nav-tabs.cont-button {
    flex-wrap: nowrap;
    overflow: auto;
    justify-content: flex-start;
  }
}
section .nav-tabs.cont-button ::-webkit-scrollbar {
  height: 20px;
}
section .nav-tabs.cont-button ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
section .nav-tabs.cont-button ::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}
section .nav-tabs.cont-button ::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
section .nav-tabs.cont-button .nav-item {
  flex: 0 0 33.3%;
  margin: 0;
  padding: 0 30px;
  text-align: center;
  margin-bottom: 30px;
}
@media (max-width: 600px) {
  section .nav-tabs.cont-button .nav-item {
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 15px;
  }
}
section .nav-tabs.cont-button .nav-item .nav-link {
  width: 270px;
  height: 55px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
}
@media (max-width: 600px) {
  section .nav-tabs.cont-button .nav-item .nav-link {
    font-size: 16px;
    width: 140px;
    height: 45px;
    margin-bottom: 15px;
  }
}
section .nav-tabs .nav-item {
  margin: 0 10px;
}
section .nav-tabs .nav-item .nav-link {
  border: 1px solid #025079;
  border-radius: 28px;
  font-size: 22px;
  color: #025079;
  height: 55px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 600px) {
  section .nav-tabs .nav-item .nav-link {
    font-size: 20px;
    width: 200px;
    height: 45px;
    margin-bottom: 15px;
  }
}
section .nav-tabs .nav-item .nav-link.active {
  background-color: #006db3;
  color: #fff;
  border: 0;
}
section .cont-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
section .cont-img .box-item {
  flex: 0 0 20%;
  overflow: hidden;
}
@media (max-width: 600px) {
  section .cont-img .box-item {
    flex: 0 0 50%;
  }
}
section .cont-img .box-item img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: auto;
  transform: scale(1);
}
section .cont-img .box-item img:hover {
  transform: scale(1.2);
}
section .our-strengths {
  list-style: none;
  text-align: center;
  line-height: 2;
  padding-inline-start: 0;
}
@media (max-width: 600px) {
  section .our-strengths {
    padding: 0;
  }
}
section .our-strengths li {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}
@media (max-width: 600px) {
  section .our-strengths li {
    font-size: 17px;
    line-height: 1.4;
    margin-bottom: 15px;
  }
}
section .download-txt {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  color: #000;
}
@media (max-width: 600px) {
  section .download-txt {
    font-size: 18px;
  }
}
section .title-download {
  font-weight: 300;
  color: #025079;
  font-size: 22px;
  text-transform: uppercase;
  text-align: center;
}
section .download-list {
  display: flex;
  list-style: none;
  justify-content: center;
  flex-wrap: wrap;
  padding-inline-start: 0;
}
@media (max-width: 600px) {
  section .download-list {
    padding: 0;
    flex-direction: column;
  }
}
section .download-list li {
  margin: 0 15px;
}
@media (max-width: 600px) {
  section .download-list li {
    width: 100%;
    text-align: center;
    margin: 0 0 10px;
  }
}
section .download-list li a {
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 300;
  color: #000;
  text-decoration: underline;
}
section.contactus-section .contactbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  text-align: center;
}
section.contactus-section .contactbox .country {
  font-size: 20px;
  font-weight: 600;
}
@media (max-width: 600px) {
  section.contactus-section .contactbox .country {
    font-size: 18px;
  }
}
section.contactus-section .contactbox .name-society {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: normal;
}
@media (max-width: 600px) {
  section.contactus-section .contactbox .name-society {
    font-size: 20px;
  }
}
section.contactus-section .contactbox p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}
section.contactus-section .box-cont {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
section.contactus-section .box-cont .contactbox {
  flex: 50%;
  margin-bottom: 20px;
}
section.contactus-section .box-cont .contactbox .country {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
section.contactus-section .box-cont .contactbox .name-society {
  font-size: 20px;
  font-weight: normal;
}
section.newsletter-section .cont-form {
  display: flex;
  flex-wrap: wrap;
}
section.newsletter-section .cont-form .left-part {
  max-width: 600px;
  margin: 0 auto;
  flex: 60%;
  padding: 0 40px;
}
@media (max-width: 600px) {
  section.newsletter-section .cont-form .left-part {
    border: 0;
    padding: 0;
  }
}
section.newsletter-section .cont-form .left-part .title-sub {
  display: none;
  font-size: 24px;
  color: #000;
  margin-bottom: 25px;
  text-align: center;
}
section.newsletter-section .cont-form .left-part form ::placeholder {
  color: #2280bd;
  opacity: 1;
  /* Firefox */
}
section.newsletter-section .cont-form .left-part form .form-group .form-control {
  border-radius: 18px;
  color: #2280bd;
  border: 1px solid #2280bd;
}
section.newsletter-section .cont-form .left-part form .form-group button {
  background-color: #2280bd;
  color: #fff !important;
  text-align: left;
}
@media (max-width: 600px) {
  section.newsletter-section .cont-form .left-part form .form-group button {
    text-align: center;
  }
}
@media (max-width: 600px) {
  section.newsletter-section .cont-form .left-part form .form-group .col-md-6:first-child input,
section.newsletter-section .cont-form .left-part form .form-group .col-md-6:first-child select {
    margin-bottom: 1rem;
  }
}
section.newsletter-section .cont-form .right-part {
  display: none;
  border-left: 1px solid #2280bd;
  flex: 40%;
  padding: 0 40px;
  position: relative;
}
@media screen and (max-width: 600px) {
  section.newsletter-section .cont-form .right-part {
    flex: 100%;
    padding: 0 20px;
  }
}
section.newsletter-section .cont-form .right-part .title-sub {
  text-align: center;
}
section.newsletter-section .cont-form .right-part .circle-dwnl {
  border: 1px solid #2280bd;
  border-radius: 50%;
  width: 50px;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 103px;
  right: 113px;
}
@media (max-width: 350px) {
  section.newsletter-section .cont-form .right-part .circle-dwnl {
    bottom: 100px;
    right: 30px;
    height: 60px;
    width: 60px;
  }
}
@media (min-width: 350px) and (max-width: 420px) {
  section.newsletter-section .cont-form .right-part .circle-dwnl {
    bottom: 90px;
    right: 60px;
    width: 70px;
    height: 70px;
  }
}
section.newsletter-section .g-recaptcha {
  position: relative !important;
}
section.newsletter-section .g-recaptcha > div {
  margin: 0 auto;
}
section.newsletter-section p.txt-privacy {
  font-size: 13px;
  margin-bottom: 0;
}
section.newsletter-section #privacy_check {
  margin-top: 5px;
}
section.newsletter-section .submit-btn {
  width: auto;
  margin: 0 auto;
  padding: 1px 30px;
}
section.social-section {
  background-color: #f4f4f4;
  padding: 40px 0 60px;
}
section.social-section .container {
  display: flex;
  flex-flow: column;
}
section.social-section .container .title-section {
  margin-bottom: 30px;
}
section .flex-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
section .flex-container input {
  width: calc(50% - 10px);
  margin-bottom: 20px;
  border: 0;
  border-bottom: 1px solid #d8d8d8;
  padding: 10px;
}
@media (max-width: 800px) {
  section .flex-container input {
    width: 100%;
  }
}
section .flex-container textarea {
  margin-top: 10px;
  width: 100%;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 10px;
}
section .flex-container .btn-form {
  margin: 15px auto;
  background-color: #2280bd;
  border-radius: 50px;
  padding: 10px 25px;
  border: 0;
  color: #fff;
}
@media (max-width: 800px) {
  section .flex-container .btn-form {
    width: 100%;
  }
}
section .flex-container .btn-form:hover {
  background-color: #025079;
}
section p.message {
  margin: 30px auto 45px;
  font-size: 22px;
  text-align: center;
}

.anchor {
  display: block;
  height: 93px;
  margin-top: -93px;
  visibility: hidden;
}

.anchor2 {
  display: block;
  height: 93px;
  margin-top: -93px;
  visibility: hidden;
}

.image-banner {
  width: 100%;
  height: 400px;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: top center;
  overflow: hidden;
  margin: 0 0 30px;
}
@media (max-width: 600px) {
  .image-banner {
    height: 200px;
  }
}
.image-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#map {
  width: 100%;
}
#map iframe {
  display: block;
  width: 100%;
  max-width: 100%;
  height: 20em;
  margin: 2rem auto;
  border: 1px solid black;
}

.load-more {
  color: #2280bd;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 20px;
  margin: 50px auto;
  display: flex;
  width: fit-content;
  border: 1px solid #2280bd;
}
@media (max-width: 600px) {
  .load-more {
    margin-bottom: 10px;
  }
}

.load-more:hover {
  background-color: #2280bd;
  text-decoration: none;
  color: #fff;
}

.mobile-on {
  display: block;
}
@media (min-width: 500px) {
  .mobile-on {
    display: none;
  }
}

.desktop-on {
  display: none;
}
@media (min-width: 500px) {
  .desktop-on {
    display: block;
  }
}

.content-page .name-logo {
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
}
.content-page .name-logo .title-page {
  font-size: 32px;
  text-transform: uppercase;
  color: #006db3;
  margin-bottom: 0;
}
@media screen and (max-width: 600px) {
  .content-page .name-logo .title-page {
    font-size: 24px;
  }
}
.content-page .name-logo .back-button {
  border: 1px solid #b1b3b4;
  color: #b1b3b4;
  border-radius: 24px;
  padding: 5px 35px;
  text-transform: uppercase;
  font-weight: normal;
}
@media screen and (max-width: 600px) {
  .content-page .name-logo .back-button {
    padding: 2px 25px;
    font-size: 12px;
  }
}
.content-page img.logo {
  width: auto;
  height: auto;
  margin: 20px 0;
}
@media screen and (max-width: 600px) {
  .content-page img.logo {
    margin-bottom: 30px;
  }
  .content-page img.logo img {
    height: 56px;
  }
}
.content-page p.text {
  font-size: 19px;
  margin-bottom: 20px;
}
@media screen and (max-width: 600px) {
  .content-page p.text {
    font-size: 18px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 600px) {
  .content-page .icon-box {
    align-items: baseline;
  }
}
.content-page .icon-box .piksel-wysiwyg-litem {
  flex: 0 0 25%;
  align-items: baseline;
}
@media screen and (max-width: 600px) {
  .content-page .icon-box .piksel-wysiwyg-litem {
    flex: 0 0 50%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
.content-page .icon-box .piksel-wysiwyg-litem figure {
  margin-bottom: 10px;
}
.content-page .icon-box .piksel-wysiwyg-litem figure img {
  width: 80px;
  height: 80px;
  padding: 15px;
}
.content-page .icon-box .piksel-wysiwyg-litem .subtitle {
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 10px;
}
.content-page .icon-box .piksel-wysiwyg-litem .sub-subtitle {
  font-size: 16px;
  padding-right: 10px;
  text-align: left;
}
@media screen and (max-width: 600px) {
  .content-page .icon-box .piksel-wysiwyg-litem .sub-subtitle {
    text-align: center;
    padding-right: 0;
  }
}
.content-page .icon-box .piksel-wysiwyg-litem .digitalia-img {
  width: 80px;
  height: auto;
  margin-bottom: 15px;
}
.content-page .dwnl-link {
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 16px;
  color: #000;
}
@media screen and (max-width: 600px) {
  .content-page .dwnl-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.text-section ul {
  list-style: none;
  margin-top: 10px;
  padding-inline-start: 0 !important;
}
.text-section ul li {
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 7px;
}
@media (max-width: 600px) {
  .text-section ul li {
    font-size: 17px;
  }
}
.text-section a {
  color: black;
  font-weight: 500;
}

.contactbox ul {
  padding-inline-start: 0 !important;
}
.contactbox ul .piksel-wysiwyg-litem a {
  color: #1769b2;
}

.footer {
  background-color: #1769b2;
  padding: 50px 0;
  margin-top: 30px;
}
@media screen and (max-width: 600px) {
  .footer {
    padding: 25px 0;
  }
}
.footer .logo-footer {
  margin-bottom: 20px;
}
@media screen and (max-width: 600px) {
  .footer .logo-footer {
    margin: 0 auto;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 600px) {
  .footer .logo-footer img {
    max-width: 75%;
    margin: 0 auto;
    display: flex;
  }
}
@media screen and (max-width: 600px) {
  .footer .content-footer {
    padding: 0 20px;
  }
}
.footer .content-footer .navbar-nav {
  border-right: 1px solid #fff;
  flex: 0 0 32%;
}
@media screen and (max-width: 600px) {
  .footer .content-footer .navbar-nav {
    flex: 0 0 100%;
    border-right: 0;
    text-align: center;
    margin-bottom: 40px;
  }
}
.footer .content-footer .navbar-nav .nav-item .nav-link {
  color: #fff;
  text-transform: uppercase;
  padding: 0.2rem 1rem;
}
.footer .content-footer .last-column {
  flex: 0 0 28%;
  padding: 0 30px;
  display: flex;
  justify-content: end;
  flex-direction: column;
}
@media screen and (max-width: 600px) {
  .footer .content-footer .last-column {
    margin-bottom: 0 !important;
  }
}
@media screen and (max-width: 600px) {
  .footer .content-footer .last-column {
    flex: 0 0 100%;
    border-right: 0;
    text-align: center;
    margin-bottom: 40px;
    justify-content: flex-start;
  }
}
.footer .content-footer .last-column ul {
  border: 0;
  flex: none;
}
@media screen and (max-width: 600px) {
  .footer .content-footer .last-column ul {
    margin-bottom: 30px;
  }
}
.footer .content-footer .last-column ul .nav-item a {
  padding: 0 1rem !important;
}
.footer .content-footer .last-column ul .nav-item a span {
  font-size: 12px;
}
.footer .content-footer .last-column a:hover {
  text-decoration: none;
}
.footer .content-footer .last-column .linkedin-flw {
  display: flex;
  align-items: flex-end;
  padding-left: 15px;
}
@media screen and (max-width: 600px) {
  .footer .content-footer .last-column .linkedin-flw {
    padding-left: 0;
    justify-content: center;
  }
}
.footer .content-footer .last-column .linkedin-flw p {
  color: #fff;
  margin-bottom: 0;
  margin-right: 8px;
  font-weight: 500;
}
.footer .content-footer .last-column .linkedin-flw img {
  width: 20px;
  margin-bottom: 7px;
}
.footer .content-footer .contact {
  padding: 0 40px;
  border-right: 1px solid #fff;
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #fff;
}
@media screen and (max-width: 600px) {
  .footer .content-footer .contact {
    flex: 0 0 100%;
    padding: 0;
    border-right: 0;
    text-align: center;
  }
}
.footer .content-footer .contact .society-name {
  font-size: 20px;
  font-weight: 400;
}
@media screen and (max-width: 600px) {
  .footer .content-footer .contact .society-name {
    font-size: 18px;
  }
}
.footer .content-footer .contact .txt-footer {
  font-size: 18px;
  margin-bottom: 0;
  line-height: 1.7;
}
.footer .content-footer .contact .txt-footer a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}
.footer .content-footer .contact .txt-footer a:link {
  color: #fff;
}

.generic_page .title-page {
  font-size: 40px;
  text-transform: uppercase;
  color: #006db3;
  margin-bottom: 0;
}
.generic_page.article img {
  max-width: 100%;
}
.generic_page.article .title-article {
  font-size: 40px;
  margin-bottom: 40px;
  color: #006db3;
  text-transform: uppercase;
}
.generic_page.article .paragraphs {
  display: flex;
  flex-flow: column;
}
.generic_page.article .paragraphs .paragraph {
  margin-bottom: 80px;
  display: inline-block;
}
.generic_page.article .paragraphs .paragraph .img__center {
  text-align: center;
}
.generic_page.article .paragraphs .paragraph .img__center img {
  margin: 0 auto 40px;
}
.generic_page.article .paragraphs .paragraph .img__left {
  float: left;
  margin-right: 30px;
  width: 33%;
}
.generic_page.article .paragraphs .paragraph .img__right {
  float: right;
  margin-left: 30px;
  width: 33%;
}
.generic_page.article .paragraphs .paragraph .title-section {
  text-align: left;
  text-transform: initial;
  font-size: 30px;
}
.generic_page.article .paragraphs .paragraph .text-section {
  text-align: left;
  margin: 0;
  padding: 0;
}

.juicer-feed {
  font-family: "Montserrat", sans-serif;
  color: #000;
}

.juicer-feed.modern .j-poster {
  display: none;
}